import { isNil, isBase64 } from '~utils';
import { b64toFile } from '@/components/CaptureModule/capture.utils';
import { reScalingImgWithCanvas } from '@/components/Packages/Captures/capture.utils';
import { store } from '@/store';

export default {
	name: 'CaptureModuleMixin',
	props: {
		value: {
			type: Boolean,
			required: true,
			default: false
		}
	},
	computed: {
		isOpen: {
			get() {
				return this.value;
			},
			/**
			 * @param {Boolean} status
			 * @return {Emitter}
			 */
			set(status) {
				this.$emit('input', status);
			}
		}
	},
	methods: {
		/**
		 * @param {Array} images
		 * @param {Number} id
		 * @return {Array}
		 */
		async loopImages(images) {
			for (let i = 0; i < images.length; i++) {
				await this.saveImage(images[i]);
			}
		},

		/**
		 * @param {String} imgData
		 * @return {File}
		 */
		async saveImage(imgData) {
			let isPdf = imgData.toString().includes('application/pdf');
			let file = null;
			if (isPdf) {
				if (!isBase64(imgData)) {
					this.capture.dispatch('pushImgToSavedList', imgData);
					return;
				}

				const base64Data = imgData.split(';base64,');
				const ext = base64Data[0].replace('data:image/', '');

				file = b64toFile(base64Data[1], `test.${ext}`, ext);
			} else {
				if (!isBase64(imgData)) {
					this.capture.dispatch('pushImgToSavedList', imgData);
					return;
				}
				file = await reScalingImgWithCanvas(imgData);
			}

			let formData = new FormData();
			formData.set('uploadimage', file);

			if (!isNil(this.parentType)) formData.append('parentType', this.parentType);
			if (!isNil(this.parentSubType)) formData.append('parentSubType', this.parentSubType);
			if (!isNil(this.parentId)) formData.append('parentId', this.parentId);

			const savedImg = await this.$axios.$post('upload', formData, {
				onUploadProgress: (pe) => console.log({ 'pe.loaded': pe.loaded }),
				headers: {
					Authorization: `Bearer ${store.state.token}`,
					'content-type': 'multipart/form-data'
				}
			});

			this.capture.dispatch('pushImgToSavedList', savedImg);
			return;
		}
	}
};
