import { makeID } from '~utils';

const authorizedTypeSource = ['upload', 'camera'];

const CaptureStore = function (self, initialState = {}) {
	if (!self) throw new Error('Capture is required.');

	this.capture = self;

	this.states = {
		captureID: '',
		typeSource: authorizedTypeSource[0],
		hasMultipleImg: false,
		instanceToken: makeID(5),

		images: [], // wokring images
		savedImages: [], // images after upload

		isLoading: false,

		crop: {
			open: false,
			currentImg: {}
		}
	};

	for (let prop in initialState) {
		if (initialState.hasOwnProperty(prop) && this.states.hasOwnProperty(prop)) {
			this.states[prop] = initialState[prop];
		}
	}

	/**
	 * @param {String} mutation
	 * @return {Function}
	 */
	this.commit = (mutation) => {
		const _m = Object.getPrototypeOf(this).mutations;
		return _m[mutation];
	};

	/**
	 * @param {String} type
	 * @return {Boolean}
	 */
	this.checkTypeSource = (type) => {
		if (!authorizedTypeSource.includes(type)) return false;
		return this.states.typeSource === type;
	};
};

CaptureStore.prototype.mutations = {
	/**
	 * @param {Object} storeContext
	 */
	initStore({ states }) {
		states.savedImages = [];
	},

	/**
	 * @param {Object} storeContext
	 * @param {String} type
	 */
	setTypeSource({ states }, type) {
		if (!authorizedTypeSource.includes(type)) throw new Error(`Source type: '${type}' not authorized`);
		states.typeSource = type || authorizedTypeSource[0];
	},

	/**
	 * @param {Object} storeContext
	 * @param {Boolean} status
	 */
	setLoading({ states }, status = true) {
		states.isLoading = status;
	},

	/**
	 * @param {Object} storeContext
	 * @param {Object} img
	 */
	setCurrImgToCrop({ states, commit }, img) {
		states.crop = {
			open: true,
			currentImg: img
		};
		commit('refreshinstanceToken');
	},

	/**
	 * @param {Object} storeContext
	 */
	removeCurrentCrop({ states, commit }) {
		states.crop = {
			open: false,
			currentImg: {}
		};
		commit('refreshinstanceToken');
	},

	/**
	 * @param {Object} storeContext
	 */
	refreshinstanceToken({ states }) {
		states.instanceToken = makeID(5);
	},

	/**
	 * @param {Object} storeContext
	 * @param {Object} imgItem
	 */
	replaceImgOnList({ states, commit }, imgItem) {
		const { img, index } = imgItem;
		states.images[index] = img;
		commit('refreshinstanceToken');
	},

	/**
	 * @param {Object} storeContext
	 * @param {String} img
	 */
	pushImgToList({ states, commit }, img) {
		states.images.push(img);
		commit('refreshinstanceToken');
	},

	/**
	 * @param {Object} storeContext
	 * @param {String} imgName
	 */
	pushImgToSavedList({ states }, imgName) {
		if (states.hasMultipleImg) {
			states.savedImages.push(imgName);
		} else {
			states.savedImages = [imgName];
		}
	}
};

/**
 * sending emit on the table
 * @param {String} key
 * @param {*} content
 * @returns {this | *}
 */
CaptureStore.prototype.emit = function (key, content) {
	return this.capture.$emit(key, content);
};

/**
 * use store mutaions with 'commit' function
 * @param {String} name
 * @param {*} args
 */
CaptureStore.prototype.dispatch = function (name, ...args) {
	const { mutations, states, commit } = this;
	if (mutations[name]) {
		const storeContext = { commit, states };
		mutations[name].apply(this, [storeContext].concat(args));
	} else {
		throw new Error(`Action not found: ${name}`);
	}
};

export default CaptureStore;
